import React from 'react'
import { Container, Col, Row, Form, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { styled } from 'styled-components'
import { FaFacebookF, FaGooglePlusG, FaTwitter, FaPhone, FaEnvelope, FaLocationDot, FaRegClock } from "react-icons/fa6";

const ContactForm = () => {
  return (
    <Wrapper Classname="ccontact-sec sec-pad">
            
            <Container>
                <div className='travel-input'>
                    <Row>

                        <h2 className="text-center mb-4">Contact Us <p className="text-post">Ask us any questions related to flight itinerary for visa, hotel bookings for visa or travel medical insurance for visa. We even appreciate a ‘Hi’ from you!</p></h2>
                        <Col sm={6}>
                            <div className="contact-detail">

                                <ul className="contact-ul">
                                    <li><span className='location'><FaLocationDot /></span>
                                        <span className='left-sie'>
                                            1135 Kildaire Farm Road, Second Floor, Suite #200, Cary, North Carolina - 27511 United States of America</span></li>
                                    <li>
                                        <span className='phone'><FaPhone /></span>
                                        <span className='left-sie'>
                                            <Link to="#">+1 (919)-263-2551</Link>,
                                            <Link to="#">+1 (919)-263-255</Link>
                                        </span>
                                    </li>
                                    <li>
                                        <span className='envelope'><FaEnvelope /></span>
                                        <span className='left-sie'>
                                            <Link to="#"> demounknown@gmail.com</Link>
                                        </span>
                                    </li>
                                </ul>

                                <p> Follow Us</p>
                                <span>
                                    <Link to="#" className="fb"><FaFacebookF /></Link>
                                    <Link to="#" className="insta"><FaTwitter /></Link>
                                    <Link to="#" className="twitter"><FaGooglePlusG /></Link>
                                </span>
                                <p>Working Hours</p>
                                <div className='d-flex'>
                                    <span className='envelope1'><span class="clock"><FaRegClock /></span>Monday-Friday: 24 Hours</span>
                                    <span className='envelope1'><span class="clock"><FaRegClock /></span>Monday-Friday: 24 Hours</span>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <Row>
                                <Col sm={6}>
                                    <Form.Group controlId="validationCustom02">

                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="First Name"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col sm={6}>
                                    <Form.Group controlId="validationCustom02">

                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="Last Name"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col sm={6}>
                                    <Form.Group controlId="validationCustom02">

                                        <Form.Control
                                            required
                                            type="email"
                                            placeholder="Your Email Address"
                                        />
                                    </Form.Group>
                                </Col>
                                <Col sm={6}>
                                    <Form.Group controlId="validationCustom02">

                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="Subject"
                                        />
                                    </Form.Group>
                                </Col>

                                <Form.Group as={Col} md="12" controlId="validationCustomUsername">
                                    <Form.Control
                                        as="textarea"
                                        placeholder="Enter your Message here.."
                                        style={{ height: '200px' }}
                                    />
                                </Form.Group>
                                <div className='button-send text-center mt-2'>
                                    <Button variant="contained">Send Message</Button>
                                </div>

                            </Row>
                        </Col>
                    </Row>
                </div>
            </Container>
        </Wrapper>
  )
}

export default ContactForm


const Wrapper = styled.section`

    margin: 50px 0;
    input{
        width: 100%;
    margin: 0 0 10px;
    border-radius: 8px;
    padding: 0 20px;
    }
    .travel-input {
        padding: 50px;
        background: #fff;
        box-shadow: #6060601a 0px 1px 10px;
        border: 1px solid #e5e5e5;
        .contact-ul li, .contact-ul b {
            font-size: 20px;
            margin: 10px 0;
            word-wrap: break-word;
        }
        .contact-ul {
            padding:0;
            li {
                display: flex;
                .fa-location-dot {
                color: #f44337;
            }
        }
        
       
        .left-sie {
            margin-left: 7px;
            margin-top: -3px;
        }
        .fa-phone {
            color: #00b055;
            
        }
         .fa-envelope {
            color: #ff6347;
           
        }
        }
        ul li{
            list-style:none;
        }
        .contact-detail{
             p {
                font-size: 20px;
                font-weight: 600;
                padding-top: 30px;
            }
        
          a {
            font-size: 20px;
            color: #000;
            margin: 0px 5px;
            text-decoration:none;
        }
        .fb {
            color: #3b5998;
            border: 2px solid;
            border-radius: 50%;
            width: 35px;
            height: 35px;
            text-align: center;
            line-height: 28px;
        }
        .insta {
            color:  #11d1ff;
            border: 2px solid;
            border-radius: 50%;
            width: 35px;
            height: 35px;
            text-align: center;
            line-height: 28px;
           
        }
        .twitter {
            color: #e54545;
            border: 2px solid;
            border-radius: 50%;
            width: 35px;
            height: 35px;
            text-align: center;
            line-height: 28px;
            
        }
        span{
            display: flex;
            
           
        }
        }
        .btn-contained {
            width: 100%;
            color: #fff;
            height: 40px;
            font-weight: 600;
        }
       
        .location {
            font-size: 27px;
    display: flex;
    padding: 0;
    margin-left: -3px;
    padding: 4px 0px;
    color: red;
}
        }
        .phone {
            color: #198754;
        }
        .envelope {
            color: #df2c2c;
        }
        .envelope1 {
            margin-right: 18px;
            font-size: 18px;
            line-height:18px;
            .clock {
                padding-right: 5px;
                color: #ad0606;
            }
        }
    }
    `;