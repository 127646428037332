import React from 'react'
import { Container, Row } from 'react-bootstrap'
import { styled } from 'styled-components'
import jumbo1 from "../../assets/images/jumbo1.jpg";

const ContactBanner = () => {
  return (
    <Wrapper className="jumbo">
                
                    <div className="jumbo-2__txt">
                        <h2>Hello!</h2>
                        <p>Our Team is available for you 24/7/365 – phone, chat and email!</p>
                    </div>
                   
                </Wrapper>
  )
}

export default ContactBanner
const Wrapper = styled.section`
    background:url(${jumbo1});
    min-height:400px;
    background-repeat: no-repeat;
    position: relative;
    align-items: center;
    justify-content: center;
    display:flex;
    background-position:bottom;
    &:after{
        content: "";
    position: absolute;
    left: -1px;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.6);
    }
    h2{
        color: #fff;
        font-size: 40px;
        font-weight: 400;
        line-height: 60px;
  
    }
    .jumbo-2__txt {
        position: relative;
        z-index: 2;
        color: #fff;
        text-align: center;
        width: 100%;
        p {
        font-size: 18px;
        color: #ffffff;
        font-weight: 400;
        line-height: 25px;
    }
   
   
}

                       
    `;