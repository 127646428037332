import React from 'react'
import { styled } from 'styled-components'
import { Container, Row, Col, Form } from 'react-bootstrap'


const VisaApplication = () => {
    return (
        <Wrapper>
            <Container>
                <div className='travel-input'>
                    <Row>
                        <h2 className="text-center mb-4">Visa Application Packages <p className="text-post">We have spent lot of time researching and aggregating all the required information into one single visa package that you need to apply for your visa without going through stress!</p></h2>
                        <Col md={12}>
                            <Form.Label>Do You Need A Visa Application Package To Guide You In The Visa Application Process?</Form.Label>

                            <Form>
                                {['radio'].map((type) => (
                                    <div key={`inline-${type}`} className="mb-3 mt-2">
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="group1"
                                            type={type}
                                            id={`inline-${type}-1`}
                                        />
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="group1"
                                            type={type}
                                            id={`inline-${type}-2`}
                                        />
                                    </div>
                                ))}
                            </Form>
                            <div class="gfield_description">(Visa application sometimes can be overwhelming and there are many things to keep track of. You can leave all the stress behind by following the important information, resources, tips and tricks outlined in these visa packages)</div>
                        </Col>
                        <Form.Group as={Col} md="6" className='mb-4 mt-3 ' controlId="validationCustomUsername">
                            <Form.Label className='mb-2'>Select Visa Package*</Form.Label>
                            <Form.Select aria-label="Default select example">
                                <option value="Please Select">Please Select</option>
                                <option value="35">Schengen Visa</option>
                                <option value="36">UK Visa</option>
                                <option value="38">Canada Visa</option>
                                <option value="38">USA Visa</option>
                            </Form.Select>
                        </Form.Group>


                    </Row>
                </div>
            </Container>
        </Wrapper>
    )
}

export default VisaApplication
const Wrapper = styled.section`
    font-weight: 500;
    margin: 0;
    background: #F7F7F7;
    padding: 60px 0px;
    .travel-input {
	padding: 50px;
	background: #fff;
    box-shadow: #6060601a 0px 1px 10px;
    border: 1px solid #e5e5e5;
    }
    
    @media (max-width: ${({ theme }) => theme.breakpoints.medium}) { 
        .travel-input {
       padding: 15px;
       
   }
}

`;