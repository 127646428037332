import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import BannerImage from '../../assets/images/banner.jpg'

const Banner = () => {
    return (
        <Wrapper className='banner'>
            <Container>
                <Row className='h-100 align-items-center'>
                    <Col sm={12}>
                        <div className='banner-row'>
                            <h1>FLIGHT. HOTEL. <br />INSURANCE.</h1>
                            <h2>Applicable For All Country Visas Worldwide!!</h2>
                            <div className='banner-para'>
                                <p>The Embassy recommends not to purchase actual tickets until the visa is approved. So why risk your money and time?</p>
                                <p>The Embassy recommends not to purchase actual tickets until the visa is approved. So why risk your money and time?</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Wrapper>
    )
}

export default Banner


const Wrapper = styled.section`
    background:url(${BannerImage});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position:relative;
    height:700px;
        &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 0;
        left: 0;
        top:0;
        opacity:0.6;
        background: -moz-linear-gradient(top,  #079561 0%, #079561 50%, #FF8227 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top,  #079561 0%,#079561 50%,#FF8227 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom,  #079561 0%,#079561 50%,#FF8227 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        }

        .container{
            height:100%;
            z-index:1;
            position:relative;
            .banner-row{
              width:70%;
                 h1{
                    font-size:90px;
                    font-weight:200;
                    color:#fff;
                    line-height:90px;
                    margin:0;
                    padding: 0px 0 30px;
                }
                h2{
                    font-size:50px;
                    font-weight:bold;
                    color:#fff;
                    line-height:50px;
                    margin:0;
                    padding: 0px 0 30px;
                }
                .banner-para {
                    border-left: 12px solid #FF8227;
                    padding-left: 15px;
                    p{
                    font-size:30px;
                    font-weight:200;
                    font-style:italic;
                    line-height:30px;
                    color:#fff;
                    margin:0;
                    padding: 0px 0 30px;

                    &:last-child{
                        padding-bottom:0px;
                    }
                }
             }
            }
        }
        @media (max-width: ${({ theme }) => theme.breakpoints.xlarge}) {
            .container{
                .banner-row{
                    width: 80%;
                       h1 {
                        font-size: 75px;
                        line-height: 84px;
                    }
                    h2 {
                        font-size: 45px;
                        line-height: 50px;
                        
                    }
            
        }
        }
    }

        @media (max-width: ${({ theme }) => theme.breakpoints.small}) {
            .container{
                .banner-row{
                    width: 100%;
             h1 {
                font-size: 45px;
                line-height: 55px;
            }
             h2 {
                font-size: 25px;
                line-height: 36px;
               
        }
        .banner-para p {
            font-size: 19px;
            line-height: 25px;
        }
        }
        }



`;